.container{
  width: 100%;
}
.header-time{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: end;
  background-color: #057acc;
  >span{
    color: #ffffff;
    font-size: 25px;
    font-family: "Apple Braille";
    display: flex;
    gap: 20px;
    margin-right: 1%;
  }

}

.logo-section{
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo{
    width: 100%;
    margin-left: 2%;
	 cursor: pointer;
  }
  >a{
	width: 18%;
 }
  .search{
    display: flex;
    justify-content: space-between;
    width: 20%;
    align-items: center;
    margin-right: 2%;

    >input{
      height: 35px;
      width: 80%;
      border: 1px solid #057acc;
    }
    >p{
      height: 35px;
      width: 19%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: #057acc;
      border-radius: 2px;

    }
  }
}

.nav-bar{
  background-color: #057acc;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3%;

  >a{
    text-decoration: unset;
    color: #ffffff;
    font-size: 20px;
	 cursor: pointer;
	 padding: 10px;
	 border-radius: 8px;
	
  }
}
.nav-bar a:hover{
		background-color: #034774;
		transition: all 1s;
	 }

.carousel{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
 padding: 50px 0;
 background-color: rgba(156, 171, 182, 0.1);

  >span{
    width: 32%;
    height: 260px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    overflow: hidden;
	 position: relative;
	 cursor: pointer;
    >img{
      width: 100%;
		height: 100%;
    }
    >p{
      padding: 10px;
      color: #ffffff;
      background-color: rgba(8, 13, 236, 0.6);
      font-size: 25px;
      position: absolute;
      top: -24px;
      left: 0px;
      border-radius: 16px 0 16px 0 ;

    }
  }
  &>span:nth-child(4), :nth-child(5), :nth-child(6), :nth-child(7), :nth-child(8){
    margin-top: 40px;
  }

}

.footer{
  width: 100%;
  height: 100px;
  background-color: #057acc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: end;
  >p{
    color: white;
    font-size: 16px;
    margin-right: 1%;
    padding: 5px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  }
}

.soc-patet{
  height: 500px;
  overflow: hidden;
}
.big-img{
  width: 100%;
}

.about-us{
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
width: 60%;
  margin-top: 20px;
  position: absolute;
  top: 80px;
  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  >p{
    font-size: 28px;
    padding: 10px 20px;
    letter-spacing: 3px;
    text-align: center;
  }
  h1{
	padding-top: 15px;
	  }
}

.about-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  >img{
	opacity: 0.3;
	width: 100%;
  }
}






.banks-info{
	border-radius: 16px;
   box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
	padding: 15px 10px 5px 20px;
	width: 20%;
	margin: 20px 0 20px 20px;
	>a{
		text-decoration: none;
		color: #057acc;
		display: flex;
		align-items: center;
		>.download{
			width: 25px;
			margin-left: 15px;
		}
	}
	

}

.callBack{
	padding: 30px 30px;
	width: 45%;
	margin: 60px auto;
	box-shadow: 0 26px 58px 0 rgba(0, 0, 0, .22), 0 5px 14px 0 rgba(0, 0, 0, .18);
	border-radius: 16px;

	>p{
		font-size: 20px;
	}
	>h1{
		text-align: center;
		margin-bottom: 15px;
	}
}